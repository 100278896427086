import { useState, useEffect } from "react";
import mapIcon from "../images/icons/map_markers/Paw_Icon_Small.png";
import "../styles/home.scss";
import pawIcon from "../images/icons/map_markers/Paw_Icon_Small.png";
import Footer from "./Footer";
import Nav from "./Nav";
import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";
import { Button, CircularProgress, TextField } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  InfoWindow,
} from "@react-google-maps/api";
let PostcodesIO = require("postcodesio-client");

export default function Home() {
  
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [markerData, setMarkerData] = useState([]);
  const [sortedMarkerData, setSortedMarkerData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userPostCode, setUserPostCode] = useState("");

  // Map container style
  const containerStyle = {
    width: "100%",
    height: "80vh",
    marginLeft: "20px",
    marginTop: "90px",
  };

  //Map centre point
  const center = {
    lat: 55.90072430176191,
    lng: -4.230517394800327,
  };

  //Load map key
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCA17XufYl3oox1qRT6aXvvM8JqmyvGC1I",
  });

  //Get Pawwash data on load
  useEffect(() => {
    axios
      .get("https://nitrous.rt-itservices.co.uk/speedqueen")
      .then((res) => {
        setMarkerData(res.data);
        //console.log(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [isLoaded]);

  //Set marker click for info window
  const handleMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  let postcodes = new PostcodesIO("https://api.postcodes.io", {
    headers: { "User-Agent": "MyAwesomeApp/1.0.0" }, // default {} - extra headers
  });

  const successCallback = async (position) => {
    const { latitude, longitude } = position.coords || null;
    const service = new window.google.maps.DistanceMatrixService();
    let markers = [];
    for (let marker of markerData) {
      const { latitude: markerLatitude, longitude: markerlongitude } =
        marker.address;
      const userPosition = { lat: latitude, lng: longitude };
      const markerPosition = { lat: markerLatitude, lng: markerlongitude };

      const result = await service.getDistanceMatrix({
        destinations: [userPosition],
        origins: [markerPosition],
        travelMode: "DRIVING",
        unitSystem: 1.0,
      });

      marker.distanceToUser = (
        result.rows[0]?.elements[0]?.distance?.value * 0.000621371
      ).toFixed(2);
      markers = [...markers, marker];
    }

    markers.sort(function (a, b) {
      return a.distanceToUser - b.distanceToUser;
    });
    setSortedMarkerData(markers);
    setIsLoading(false);
  };

  const searchWithPostCode = async () => {
    if (userPostCode !== "") {
      try {
        const result = await postcodes.lookup(userPostCode);
        if (result) {
          const { latitude, longitude } = result || undefined;

          successCallback({
            coords: {
              latitude: latitude,
              longitude: longitude,
            },
          });
        } else {
          window.alert(
            "Unable to fetch your postcode location,Please make sure you have entered the correct postcode"
          );
        }
      } catch (e) {
        window.alert("Unable to fetch your postcode location");
      }
    }
  };

  const errorCallback = (error) => {
    setIsLoading(false);
    console.log("Error fallback:", error);
    window.alert(
      "Sorry, we are unable to get your location at this time. Please check your location setting"
    );
  };

  return (
    <main className="home">
      <Nav page={"home"} />
      <div className="location__container">
        {isLoaded ? (
          <section
            style={{
              display: "flex",
              margin: "0 20px",
              justifyContent: "space-bettwen",
            }}
          >
            <div className="search_store">
              <div className="locater_find_heading">
                <SearchIcon fontSize="large" style={{ marginRight: "10px" }} />
                <Typography variant="h5" component="h2">
                  Find a store
                </Typography>
              </div>
              <div className="searchBtn">
                <div style={{ display: "flex" }}>
                  <TextField
                    id="outlined-basic"
                    label="Enter your post code"
                    value={userPostCode}
                    onChange={(e) => setUserPostCode(e.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      searchWithPostCode();
                    }}
                    style={{
                      marginLeft: "20px",
                      width: "200px",
                      backgroundColor: "rgba(226, 255, 63, 0.57)",
                      color: "black",
                    }}
                  >
                    Search
                  </Button>
                </div>
                <div className="findWIthLocation">
                  <Button
                    variant="contained"
                    onClick={() => {
                      setIsLoading(true);
                      navigator.geolocation.getCurrentPosition(
                        successCallback,
                        errorCallback
                      );
                    }}
                    endIcon={<MyLocationOutlinedIcon />}
                    style={{
                      padding: "7.5px 15px 7.5px 15px",
                      borderRadius: "30px",
                      backgroundColor: "rgba(226, 255, 63, 0.57)",
                      color: "black",
                      marginTop: "10px",
                    }}
                  >
                    Find a store near me
                  </Button>
                </div>
              </div>
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "50px",
                  }}
                >
                  <CircularProgress color="info" />
                </div>
              ) : sortedMarkerData.length > 0 ? (
                <div className="eachStoreContainer">
                  {sortedMarkerData &&
                    sortedMarkerData.map((marker, index) => {
                      if (marker.hasPawwash) {
                        return (
                          <div className="singleStoreContainer" key={index}>
                            <h3>{marker.name}</h3>
                            <p>{marker.address.full}</p>
                            <p>
                              {marker.hasPawwash ? "Pawwash available" : null}
                            </p>
                            <p>
                              {marker.hours["24Hours"]
                                ? "24/7 Open"
                                : marker.hours["open"] +
                                  " - " +
                                  marker.hours["close"]}
                            </p>
                          </div>
                        );
                      }
                      return null;
                    })}
                </div>
              ) : (
                <div className="eachStoreContainer">
                  {markerData &&
                    markerData.map((marker, index) => {
                      if (marker.hasPawwash) {
                        return (
                          <div className="singleStoreContainer" key={index}>
                            <h3>{marker.name}</h3>
                            <p>{marker.address.full}</p>
                            <p>
                              {marker.hasPawwash ? "Pawwash available" : null}
                            </p>
                            <p>
                              {marker.hours["24Hours"]
                                ? "24/7 Open"
                                : marker.hours["open"] +
                                  " - " +
                                  marker.hours["close"]}
                            </p>
                          </div>
                        );
                      }
                      return null;
                    })}
                </div>
              )}
            </div>

            <div className="mapContainer">
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={10}
                options={{ streetViewControl: false }}
              >
                {markerData && markerData.length > 0
                  ? markerData
                      .filter((_, index) => !index.hasPawwash) // Only index 0 and 2
                      .map(function (marker, index) {
                        if (marker.hasPawwash) {
                          return (
                            <MarkerF
                              icon={mapIcon}
                              position={{
                                lat: marker.address.latitude,
                                lng: marker.address.longitude,
                              }}
                              onClick={() => handleMarkerClick(marker)}
                              key={`${index}-map`}
                            />
                          );
                        }
                        return null;
                      })
                  : null}
                {selectedMarker ? (
                  <InfoWindow
                    position={{
                      lat: selectedMarker.address.latitude,
                      lng: selectedMarker.address.longitude,
                    }}
                    onCloseClick={() => setSelectedMarker(null)}
                  >
                    <>
                      <div className="infowindow_header">
                        <img src={pawIcon} alt="pawIcon" />
                        <h1>{selectedMarker.name}</h1>
                      </div>
                      <div className="store_info">
                        <p style={{ fontSize: "14px" }}>
                          <LocationOnIcon />
                          <b>Address: </b>{" "}
                          {`${selectedMarker.address.full}, ${selectedMarker.address.postcode}`}
                        </p>
                        <p>
                          {selectedMarker.hasPawwash
                            ? "Pawwash available"
                            : null}
                        </p>
                        <p>
                          <AccessTimeIcon />
                          {selectedMarker.hours["24Hours"]
                            ? "24/7 Open"
                            : selectedMarker.hours["open"] +
                              " - " +
                              selectedMarker.hours["close"]}
                        </p>
                      </div>
                    </>
                  </InfoWindow>
                ) : null}
              </GoogleMap>
            </div>
          </section>
        ) : null}
      </div>
      <Footer />
    </main>
  );
}
