import "../styles/demo.scss";
import Nav from "./Nav";
import Footer from "./Footer";
import step1 from "../images/instructions/IMAGE 1.png";
import step2 from "../images/instructions/IMAGE 2.png";
import step3 from "../images/instructions/IMAGE 3.png";
import step4 from "../images/instructions/IMAGE 4.png";
import step5 from "../images/instructions/IMAGE 5.png";
import step6 from "../images/instructions/IMAGE 6.png";
import step7 from "../images/instructions/IMAGE 7.png";

const Demo = () => {
    return (
        <>
        <main className="demo" >
            <Nav page={"demo"} />
            <div className="instructions_row">
                <div className="steps">
                    <img src={step2} className="" alt="Shampoo" />
                    <div className="instructions">
                        <p>1) Work through coat by hand.</p>
                        <h5><br/>(Approx. time: 1 - 2 minutes)</h5>
                    </div>
                </div>
                <div className="steps">
                    <img src={step3} className="" alt="Warm Rinse" />
                    <div className="instructions">
                        <p>2) Use as required.</p>
                        <h5><br/><br/>(Approx. time: 1 - 2 minutes)</h5>
                    </div>
                </div>
                <div className="steps">
                    <img src={step4} className="" alt="Conditioner" />
                    <div className="instructions">
                        <p>3) For a healthy coat.</p>
                        <h5><br/><br/>(Approx. time: 1 - 2 minutes)</h5>
                    </div>
                </div>
                <div className="steps">
                    <img src={step5} className="" alt="Anti-Flea Shampoo" />
                    <div className="instructions">
                        <p>4) Rinse for flea control (Leave in).</p>
                        <h5><br/>(Approx. time: 30 - 60 seconds)</h5>
                    </div>
                </div>
                <div className="steps">
                    <img src={step6} className="" alt="Blow Dry" />
                    <div className="instructions">
                        <p>5) Blow dry coat on low or high setting.</p>
                        <h5><br/>(Approx. time: 5 minutes)</h5>
                    </div>
                </div>
                <div className="steps">
                    <img src={step7} className="" alt="Disinfect Tub" />
                    <div className="instructions">
                        <p>
                            6) Take dog out of tub. For hygiene purposes, rinse
                            tub.
                        </p>
                        <h5>(Approx. time: 30 - 60 seconds)</h5>
                    </div>
                </div>
            </div>
            <br/>
            <Footer />
        </main> 
       
        </>
    );
};

export default Demo;
